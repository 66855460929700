



































import { Date } from '@/mixins/date';
import { Action } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FullScreenLoading from '@/components/common/FullScreenLoading.vue';
import { WorkflowState, Integration } from '@/generated/graphql';
import { ActionMethod } from 'vuex';
import { WorkflowActions } from '../../../store/workflow/actions.workflow';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import { CreateDirectUploadMutationMutation } from '../../../generated/graphql';

@Component({ components: { FullScreenLoading }, props: {}, methods: {} })
export default class FileChooser extends mixins(Date) {
  fileUploadDialog = false;
  @Prop()
  integration!: Integration;
  @Prop()
  fieldName!: string;
  @Prop()
  state!: WorkflowState;
  @Prop()
  activatorClass!: string;
  @Prop({ default: () => false })
  disabled!: boolean;
  @Action(`workflow/${WorkflowActions.MUTATION_CREATE_DIRECT_UPLOAD}`)
  performCreateDirectUpload!: ActionMethod;

  loading = false;

  get fileAccept() {
    if (this.fieldName == 'test_case_files') return '.pdf, .xls, .xlsx';
    return '*';
  }

  async directUpload(url: string, headers: Record<string, string>, file: File): Promise<BlobUpload> {
    const upload = new BlobUpload({ file, directUploadData: { url, headers } });
    return new Promise((resolve, reject) => {
      upload.create(error => {
        if (error) {
          reject(error);
        } else {
          resolve(upload);
        }
      });
    });
  }

  async submitFile(file: File) {
    this.loading = true;
    FileChecksum.create(file, async (error, checksum) => {
      if (error) {
        console.error(error);
        this.loading = false;
      } else {
        try {
          const dUploadResponse: CreateDirectUploadMutationMutation = await this.performCreateDirectUpload({
            checksum: checksum,
            filename: file.name,
            contentType: file.type || 'binary/octet-stream',
            byteSize: file.size,
          });
          await this.directUpload(
            dUploadResponse.createDirectUpload?.directUpload?.url!,
            JSON.parse(dUploadResponse.createDirectUpload?.directUpload?.headers!),
            file
          );
          this.$emit('addedFile', {
            fieldName: this.fieldName,
            filename: file.name,
            id: dUploadResponse.createDirectUpload?.directUpload?.blobId,
            signedId: dUploadResponse.createDirectUpload?.directUpload?.signedBlobId,
          });
          // @ts-ignore
          /* eslint-disable @typescript-eslint/camelcase */
          this.$gtag.event('upload_file', {
            event_category: 'workflow',
            event_label: 'upload file to workflow state',
            value: this.fieldName,
          });
        } catch (reason) {
          console.error(reason);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  handleFiles() {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const input: any = this.$refs.file;
    const file: File = input.files.item(0);
    this.submitFile(file);
    ((this.$refs.file as unknown) as { [k: string]: string }).value = '';
  }
}
