














































































import Vue from 'vue';
//@ts-ignore
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  //@ts-ignore
} from 'tiptap-extensions';
export default Vue.extend({
  name: 'Editor',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      default: '',
    },
    basic: {
      default: true,
    },
  },
  watch: {
    value(to: string) {
      if (to === '') {
        this.editor.clearContent();
        this.editor.focus();
      }
    },
  },
  data(): { editor: Editor } {
    return { editor: null };
  },
  methods: {
    getColor: (active: boolean) => {
      return active ? 'primary' : '';
    },
    focus() {
      this.editor.focus();
    },
    update(info: Editor) {
      this.$emit('input', info.getHTML());
    },
  },
  mounted() {
    (this.editor = new Editor({
      autoFocus: true,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      content: this.value,
      onUpdate: this.update.bind(this),
    })),
      this.editor.focus();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
});
