





























import { Workflow, WorkflowState, WorkflowTransitionMutationVariables } from '@/generated/graphql';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { CoreActions } from '@/store/core/actions.core';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import AcknowledgementDialog from '@/components/integrations/workflow/AcknowledgementDialog.vue';

@Component({
  components: { AcknowledgementDialog },
})
export default class TransitionMenu extends Vue {
  show = false;
  transitions: {}[] = [];

  $refs!: {
    acknowledgement: HTMLFormElement;
  };

  @Getter(`workflow/${WorkflowGetter.IS_LOADING}`)
  loading!: boolean;

  @Prop()
  workflow!: Workflow;

  @Prop()
  currentState!: WorkflowState;

  @Prop({ default: 'workflow.transitionDialog.activator' })
  btnText!: string;

  async open() {
    await this.getPossibleTransitions();
    this.show = true;
  }

  @Action(`workflow/${WorkflowActions.FETCH_TRANSITIONS}`)
  performFetchTransitions!: ActionMethod;

  @Action(`workflow/${WorkflowActions.MUTATION_WORKFLOW_TRANSITION}`)
  performWorkflowTransition!: ActionMethod;

  @Action(`core/${CoreActions.FETCH_SELECTED_INTEGRATION}`)
  updateIntegration!: ActionMethod;

  async getPossibleTransitions() {
    this.transitions = await this.performFetchTransitions();
  }

  async performUpdate(payload: WorkflowTransitionMutationVariables) {
    if (payload) {
      await this.performWorkflowTransition(payload);

      const integrationId = this.workflow.integrationId;
      this.updateIntegration({ integrationId });
    }
  }

  doTransition(t: { event: string; state: string }) {
    this.$refs.acknowledgement.acknowledgementLastClicked = t;
    if (!this.$refs.acknowledgement.acknowledgementResponse) {
      this.$refs.acknowledgement.acknowledgementDialog = true;
      return;
    }
    const payload: WorkflowTransitionMutationVariables = {
      workflowId: this.workflow.id.toString(),
      stateType: { state: t.state, event: t.event },
    };
    this.performUpdate(payload);
    this.show = false;

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('state_transition', {
      event_category: 'workflow',
      event_label: 'state transition in workflow',
      value: t.event,
    });
  }
}
