







































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import {
  UpdateWorkflowStateMutationVariables,
  Workflow,
  WorkflowState,
  Integration,
  Scalars,
} from '@/generated/graphql';
import { mixins } from 'vue-class-component';
import { Date } from '@/mixins/date';
import { Env } from '@/mixins/env';
import CommentDialog from '@/components/integrations/workflow/CommentDialog.vue';
import TransitionMenu from '@/components/integrations/workflow/TransitionMenu.vue';
import AttachmentMenu from '@/components/integrations/workflow/AttachmentMenu.vue';
import AddFileDialog from '@/components/integrations/workflow/AddFileDialog.vue';
import AddLinkDialog from '@/components/integrations/workflow/AddLinkDialog.vue';
import FieldInputRenderer from '@/components/integrations/workflow/FieldInputRenderer.vue';
import { Action, Getter } from 'vuex-class';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { ActionMethod } from 'vuex';
import { CoreGetter } from '@/store/core/getter.core';
import { urlRegex } from '@/helpers/integrationHelper';
import Vue from 'vue';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { ClientInfo } from 'centrifuge';
import Viewer from '@/components/Viewer.vue';
import { productDeprecated } from '@/helpers/integrationHelpers';

@Component({
  components: {
    Viewer,
    TransitionMenu,
    AddFileDialog,
    AttachmentMenu,
    AddLinkDialog,
    CommentDialog,
    FieldInputRenderer,
  },
  methods: { getCssClassFromState, productDeprecated },
})
export default class WorkflowStateCard extends mixins(Date, Env) {
  urlRegex = urlRegex;
  @Prop({ default: () => false })
  current!: boolean;
  @Prop()
  state!: WorkflowState;
  @Prop()
  workflow!: Workflow;
  @Prop()
  workflowIndex!: number;
  @Action(`workflow/${WorkflowActions.MUTATION_UPDATE_WORKFLOW_STATE}`)
  performUpdateWorkflowMutation!: ActionMethod;

  @Action(`workflow/${WorkflowActions.UNLOCK_WORKFLOW}`)
  performUnlock!: ActionMethod;

  @Action(`workflow/${WorkflowActions.FETCH_WORKFLOW}`)
  performWorkflowCall!: ActionMethod;

  @Action(`workflow/${WorkflowActions.MUTATION_ATTACH_FILE_TO_STATE}`)
  performAttachFileToState!: ActionMethod;

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  integration!: Integration | null;

  @Getter(`workflow/${WorkflowGetter.GET_WORKFLOW_LOCK}`)
  workflowLock: ClientInfo | undefined;

  get errorField() {
    return this.state.fields.find((f: { name: string; value: string }) => f.name === 'error_data');
  }

  get editDisabled() {
    return !this.current || this.workflowLock != undefined || this.deprecated || !this.integration?.isActive;
  }

  get deprecated() {
    return productDeprecated(this.integration, this.workflow.product);
  }

  fieldsData: { [name: string]: string | string[] | {}[] | boolean } = {};
  fieldsDisplay: { [name: string]: string | string[] } = {};
  preSaveActions: { [name: string]: () => void } = {};
  unlockSave = false;
  errorFieldsData: { [name: string]: string | string[] | {}[] | boolean } = {};
  // @ts-ignore
  fieldNames = this.$t(`workflow.details.fields`) as { [name: string]: string };

  indexOfField(s: string) {
    let i = 0;
    for (const key in this.fieldNames) {
      if (key == s) {
        return i;
      }
      i++;
    }
    return -1;
  }

  @Watch('state')
  onStateChange(to: WorkflowState) {
    this.fieldsData = {};
    this.errorFieldsData = {};
    to.fields?.sort((a: Scalars['Json'], b: Scalars['Json']) => this.indexOfField(a.name) - this.indexOfField(b.name));

    for (const field of to.fields) {
      if (field.type == 'text') {
        this.errorFieldsData[field.name] = field.value || '';
      }
      if (Array.isArray(field.type) && field.type[0] === 'link' && field.type.length > 1 && field.type[1].array) {
        this.fieldsData[field.name] = field.value || [];
        this.fieldsDisplay[field.name] = [];
        (this.fieldsData[field.name] as string[]).forEach((element: string) => {
          (this.fieldsDisplay[field.name] as string[]).push(
            to.workflowStateLinks?.find(sl => sl.id == element)?.link as string
          );
        });
      } else if (field.type == 'link') {
        this.fieldsData[field.name] = field.value || [];
        const link = to.workflowStateLinks?.find(sl => `${sl.id}` == field.value);
        this.fieldsDisplay[field.name] = link ? link.link! : '';
      } else if (field.type === 'boolean') {
        this.fieldsData[field.name] = field.value == true;
      } else {
        this.fieldsData[field.name] = field.value || '';
      }
    }
  }

  created() {
    this.onStateChange(this.state);
  }

  async save() {
    for (const k in this.preSaveActions) {
      await this.preSaveActions[k]();
    }
    if (this.errorFieldsData != {}) {
      this.fieldsData['error_data'] = this.errorFieldsData['error_data'];
    }
    const payload: UpdateWorkflowStateMutationVariables = {
      workflowStateId: `${this.state.id}`,
      data: this.fieldsData,
      assignee: 'system@fox.nlsn-eng-ops.com',
    };
    await this.performUpdateWorkflowMutation(payload);
    Vue.nextTick(() => {
      this.unlockSave = false;
    });

    this.performUnlock();
  }

  get isProdState() {
    return this.state.stateType === 'WorkflowStates::Production';
  }
}
