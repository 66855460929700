
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class FileUpload extends Vue {
  show = false;
  @Prop()
  label!: string;
  file: File | null = null;
  $refs!: {
    file: HTMLFormElement;
  };
  elementClass = '';

  drop(event: DragEvent) {
    if (event.dataTransfer) {
      this.file = event.dataTransfer.files[0];
    }
  }
  dragEnter(event: DragEvent) {
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'link';
      this.elementClass = 'drag-enter';
    }
  }
  dragLeave() {
    this.elementClass = '';
  }
}
