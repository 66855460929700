




import Vue from 'vue';
export default Vue.extend({
  name: 'ResultRenderer',
  props: {
    content: {
      default: '',
    },
  },
});
