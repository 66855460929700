


























































































































































































import {
  AttachWorkflowStateFileMutationMutation,
  Integration,
  Scalars,
  UpdateWorkflowStateLinkMutationVariables,
  Workflow,
  WorkflowState,
  Maybe,
} from '@/generated/graphql';
import { Date } from '@/mixins/date';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import { urlRegex } from '@/helpers/integrationHelper';
import CatTestsession from '@/components/integrations/workflow/CatTestsession.vue';
import FileChooser from '@/components/integrations/workflow/FileChooser.vue';
import moment from 'moment';

@Component({ components: { CatTestsession, FileChooser }, props: {}, methods: {} })
export default class FileInputRenderer extends mixins(Date) {
  urlRegex = urlRegex;
  datepickermenu = false;
  showError = false;

  @Prop()
  editDisabled!: boolean;
  @Prop()
  field!: Scalars['Json'];
  @Prop()
  errorField!: Scalars['Json'];
  @Prop()
  workflow!: Workflow;
  @Prop()
  fieldsData!: { [name: string]: string | string[] | {}[] | boolean };
  @Prop()
  errorFieldsData!: { [name: string]: string | string[] | {}[] | boolean };
  @Prop()
  fieldsDisplay!: { [name: string]: string | string[] };
  @Prop()
  integration!: Integration;
  @Prop()
  preSaveActions!: { [name: string]: () => void };
  @Prop()
  state!: WorkflowState;

  @Action(`workflow/${WorkflowActions.MUTATION_ATTACH_FILE_TO_STATE}`)
  performAttachFileToState!: ActionMethod;
  @Action(`workflow/${WorkflowActions.MUTATION_UPDATE_WORKFLOW_STATE_LINK}`)
  performStateLinkAction!: ActionMethod;
  @Action(`workflow/${WorkflowActions.FETCH_FILE_URL}`)
  performFetchFileUrl!: ActionMethod;
  @Action(`workflow/${WorkflowActions.LOCK_WORKFLOW}`)
  performLock!: ActionMethod;

  catLink(catId: string) {
    return `//${process.env.VUE_APP_CAT_URL_BASE}${catId}/stream`;
  }

  generateLabel(fieldName: string): string {
    if (this.state.validation) {
      const fieldValidation = this.state.validation[fieldName];
      if (fieldValidation) {
        const isRequired = this.checkIfRequired(fieldValidation);
        return this.getNameForField(fieldName) + (isRequired ? ' *' : '');
      }
    }
    return this.getNameForField(fieldName).toString();
  }

  hasDependant(fieldName: string) {
    if (this.state.validation) {
      return this.state.validation[fieldName] && this.state.validation[fieldName].dependant.length > 0;
    }
    return false;
  }

  getNameForField(fieldName: string) {
    return this.$t(`workflow.details.fields.${fieldName}`);
  }

  checkIfRequired(fieldValidation: Maybe<Scalars['Json']>): boolean {
    return (
      fieldValidation.required &&
      fieldValidation.dependant.length === 0 &&
      !(fieldValidation.required_in_recertification == false && this.workflow.recertReference != null)
    );
  }

  formatedDatepickerResult() {
    const date = this.fieldsData[this.field.name].toString();
    return date ? moment(date).format(this.defaultDateTimeFormat) : '';
  }

  inputChanged(fieldName: string) {
    this.performLock();
    const field = this.state.fields.find((f: { name: string; value: string }) => f.name === fieldName);
    if (field.name == 'is_error') {
      this.checkAndUpdateError(field);
    }
    if (field) {
      if (field.type == 'link' && !this.preSaveActions[fieldName]) {
        this.preSaveActions[fieldName] = async () => {
          const payload: UpdateWorkflowStateLinkMutationVariables = {
            link: this.fieldsDisplay[fieldName] as string,
            linkType: 'generic',
            workflowStateId: `${this.state.id}`,
          };
          this.fieldsData[fieldName] = await this.performStateLinkAction(payload);
        };
      } else {
        this.$emit('dataChanged', true);
      }
    }
  }

  removeLink(fieldName: string, index: number) {
    if (index < (this.fieldsData[fieldName] as []).length) {
      (this.fieldsData[fieldName] as string[]).splice(index, 1);
      (this.fieldsDisplay[fieldName] as string[]).splice(index, 1);
      this.$emit('dataChanged', true);
      this.$forceUpdate();
    }
  }

  addedCatLink(data: { [key: string]: string }) {
    (this.fieldsDisplay['cat_logs'] as string[]).push(data.text as string);
    (this.fieldsData['cat_logs'] as string[]).push(data.id as string);
    this.$emit('dataChanged', true);
    this.inputChanged(data.fieldName);
    this.$forceUpdate();
  }

  async updateFiles(data: { [k: string]: string }) {
    try {
      const attachmentData: AttachWorkflowStateFileMutationMutation = await this.performAttachFileToState({
        signedBlobId: data.signedId,
        blobId: parseInt(data.id),
        workflowStateId: this.state.id,
      });

      if (!this.fieldsData[data.fieldName!]) {
        this.fieldsData[data.fieldName!] = [];
      }

      (this.fieldsData[data.fieldName!] as {}[]).push({
        name: data.filename,
        id: attachmentData!.attachWorkflowStateFile!.attachmentId,
      });
    } catch (reason) {
      console.error(reason);
    }
    this.$emit('dataChanged', true);
    this.inputChanged(data.fieldName);
    this.$forceUpdate();
  }

  async download(id: number) {
    const fileData = await this.performFetchFileUrl({ workflowStateId: `${this.state.id}`, attachmentId: `${id}` });
    window.open(fileData.fetchWorkflowFileUrl!.url);
  }

  removeFile(fieldName: string, index: number) {
    if (index < (this.fieldsData[fieldName] as []).length) {
      (this.fieldsData[fieldName] as {}[]).splice(index, 1);
      this.$emit('dataChanged', true);
      this.$forceUpdate();
    }
  }

  checkAndUpdateError(field: Scalars['Json']) {
    if (field.name == 'is_error' && (this.fieldsData[field.name] == true || this.errorFieldsData['error_data'] != '')) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  mounted() {
    this.checkAndUpdateError(this.field);
  }
}
