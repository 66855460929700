





































import { Date } from '@/mixins/date';
import { Action } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  WorkflowState,
  Integration,
  FetchCatSessionsQueryVariables,
  UpdateWorkflowStateLinkMutationVariables,
} from '@/generated/graphql';
import { CatActions } from '@/store/cat/actions.cat';
import { ActionMethod } from 'vuex';
import { WorkflowActions } from '../../../store/workflow/actions.workflow';

@Component({ components: {}, props: {}, methods: {} })
export default class CatTestsession extends mixins(Date) {
  fileUploadDialog = false;
  loadingCat = true;
  @Prop()
  integration!: Integration;
  @Prop()
  state!: WorkflowState;
  @Prop()
  activatorClass!: string;
  @Prop({ default: () => false })
  disabled!: boolean;
  @Action(`cat/${CatActions.FETCH_TESTSESSION}`)
  performFetchAction!: ActionMethod;
  @Action(`workflow/${WorkflowActions.MUTATION_UPDATE_WORKFLOW_STATE_LINK}`)
  performStateLinkAction!: ActionMethod;

  fieldsDisplay: string[] | {}[] = [];

  async fetchTestSessions() {
    this.loadingCat = true;
    const payload: FetchCatSessionsQueryVariables = {
      appId: this.integration.appId!,
    };
    this.fieldsDisplay = await this.performFetchAction(payload);
    this.loadingCat = false;
  }

  async onAddLink(theLink: string) {
    const payload: UpdateWorkflowStateLinkMutationVariables = {
      link: theLink,
      linkType: 'cat_logs',
      workflowStateId: `${this.state.id}`,
    };
    const linkId = await this.performStateLinkAction(payload);

    this.$emit('addedLink', { id: linkId, text: theLink });
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('attach_cat_ts', {
      event_category: 'workflow',
      event_label: 'attach cat ts to workflow state',
    });
  }
}
