































import IntegrationDetails from '@/components/integrations/IntegrationDetails.vue';
import WorkflowHistory from '@/components/integrations/workflow/WorkflowHistory.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { Application, Integration, WorkflowState } from '@/generated/graphql';

@Component({
  components: { IntegrationDetails, WorkflowHistory },
})
export default class WorkflowDetailSidebar extends Vue {
  @Prop()
  application!: Application;
  @Prop()
  integration!: Integration;
  @Prop()
  activeProduct!: string;
  @Prop()
  workflowStates!: WorkflowState[];

  currentTab = 0;

  @Watch('currentTab')
  onCurrentTabChange() {
    const ele = this.$refs.tabContent as Element;
    ele.scrollTo({ top: 0 });
  }
}
