














































































import { getCssClassFromState } from '@/helpers/integrationHelper';
import {
  WorkflowState,
  Comment,
  CreateCommentMutationPayload,
  UpdateCommentMutationPayload,
} from '@/generated/graphql';
import { Date } from '@/mixins/date';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import Editor from '@/components/external/editor/Editor.vue';
import EditorResultRenderer from '@/components/external/editor/ResultRenderer.vue';

@Component({ methods: { getCssClassFromState }, components: { Editor, EditorResultRenderer } })
export default class CommentDialog extends mixins(Date) {
  input = '';
  show = false;
  loading = false;
  editedCommentId = 0;
  @Prop()
  activatorClass!: string;
  @Prop()
  state!: WorkflowState;
  @Prop()
  current!: boolean;
  @Prop()
  workflowIndex!: number;
  @Prop({
    required: false,
    default: () => false,
  })
  disabled!: boolean;

  @Action(`workflow/${WorkflowActions.MUTATION_ADD_COMMENT}`)
  performAddComment!: ActionMethod;
  @Action(`workflow/${WorkflowActions.MUTATION_UPDATE_COMMENT}`)
  performUpdateComment!: ActionMethod;

  $refs!: {
    editor: HTMLFormElement;
  };

  get comments(): Comment[] {
    if (this.state.comments) {
      return this.state.comments.sort((a, b) => b.id - a.id);
    }
    return [];
  }

  get isEditable() {
    return this.current && this.workflowIndex == 0 && !this.disabled;
  }

  async addComment() {
    this.commentModification(
      -1,
      async (): Promise<CreateCommentMutationPayload> => {
        return this.performAddComment({
          content: this.input,
          workflowStateId: this.state.id,
          workflowIndex: this.workflowIndex,
        });
      }
    );

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('add_comment', {
      event_category: 'workflow',
      event_label: 'add comment to workflow step',
    });
  }

  async updateComment(commentId: number) {
    this.commentModification(
      commentId,
      async (id: number): Promise<UpdateCommentMutationPayload> => {
        return this.performUpdateComment({
          content: this.input,
          id: id,
          workflowStateId: this.state.id,
          workflowIndex: this.workflowIndex,
        });
      }
    );
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('update_comment', {
      event_category: 'workflow',
      event_label: 'update comment on workflow step',
    });
  }

  async commentModification(
    commentId: number,
    modification: (id: number) => Promise<CreateCommentMutationPayload | UpdateCommentMutationPayload>
  ) {
    this.loading = true;
    const data = await modification(commentId);
    if (data) {
      this.input = '';
      this.editedCommentId = 0;
    }
    this.loading = false;
  }

  editComment(comment: Comment) {
    this.editedCommentId = comment.id;
    this.$refs.editor.editor.setContent(comment.content!);
    this.input = comment.content!;
  }
}
