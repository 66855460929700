















































import { FetchWorkflowLogsQueryVariables, Version, Scalars } from '@/generated/graphql';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { Date } from '@/mixins/date';
import { mixins } from 'vue-class-component';
import { getCssClassFromState } from '@/helpers/integrationHelper';

@Component({
  methods: { getCssClassFromState },
})
export default class WorkflowHistoryDialog extends mixins(Date) {
  dialog = false;
  headers = [
    { text: 'Certification Step', value: 'itemSubtype', align: 'left', sortable: false },
    { text: 'Timestamp', value: 'createdAt', align: 'left', sortable: true, width: '200' },
    { text: 'Event', value: 'event', align: 'left', sortable: false, width: '150' },
    { text: 'User', value: 'whodunnit', align: 'left', sortable: true, width: '200' },
    { text: 'Changes', value: 'objectChanges', align: 'left', sortable: false },
  ];
  noDisplay = ['fields', 'state_type', 'user', 'id', 'workflow_id', 'workflow_state_id'];

  @Getter(`workflow/${WorkflowGetter.GET_WORKFLOW_HISTORY}`)
  history!: Version[] | null;

  stringHumanize(str: string) {
    if (str != null) {
      const chars = str.split('_');
      for (let i = 0; i < chars.length; i++) {
        chars[i] = chars[i].charAt(0).toUpperCase() + chars[i].slice(1);
      }
      return chars.join(' ');
    }
  }

  displayObjectChange(item: Version['objectChanges'], key: string) {
    if (this.noDisplay.includes(key)) return false; // remove duplicate and redundant 'changes' to keep thing simple for users
    if (this.isNotEmptyVal(item[0]) || this.isNotEmptyVal(item[1])) {
      return true;
    }
    return false;
  }

  // don't display "empty" changes "non developer" wouldn't understand e.g. '' -> []
  isNotEmptyVal(item: Scalars['Json']) {
    return (item && item.length) || typeof item === 'boolean';
  }

  humanizeChanges(val: Scalars['Json']) {
    if (!Array.isArray(val)) return val;

    return val.map(v => {
      return v.name || v.link || v;
    });
  }

  async showHistory() {
    await this.getWorkflowHistory();
    this.dialog = true;
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_history', {
      event_category: 'workflow',
      event_label: 'open history of workflow',
      value: this.$route.params.integrationId,
    });
  }

  @Action(`workflow/${WorkflowActions.FETCH_WORKFLOW_LOGS}`)
  performFetchLogs!: ActionMethod;

  async getWorkflowHistory() {
    const product = this.$route.params.product;
    const integrationId = parseInt(this.$route.params.integrationId);
    const workflowLogsPayload: FetchWorkflowLogsQueryVariables = { integrationId: integrationId, product: product };
    await this.performFetchLogs(workflowLogsPayload);
  }
}
