









































import { Date } from '@/mixins/date';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { WorkflowState } from '@/generated/graphql';
import AddFileDialog from '@/components/integrations/workflow/AddFileDialog.vue';
import AddLinkDialog from '@/components/integrations/workflow/AddLinkDialog.vue';

@Component({ components: { AddFileDialog, AddLinkDialog }, props: {}, methods: {} })
export default class AttachmentMenu extends mixins(Date) {
  fileUploadDialog = false;
  @Prop()
  state!: WorkflowState;
  @Prop()
  activatorClass!: string;

  onAddLink() {
    console.log('link');
  }
  onAddFile() {
    console.log('file');
  }
}
