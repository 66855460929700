var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"t",rawName:"v-t",value:('workflow.details.addAttachment'),expression:"'workflow.details.addAttachment'"}],class:_vm.activatorClass,attrs:{"color":"primaryButtonColor","dark":""}},'v-btn',attrs,false),on))]}}])},[_c('v-list',[_c('add-link-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:('link'),expression:"'link'"}]}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('workflow.details.attachment.linkSubtitle', { stateName: _vm.$t(("workflow." + (_vm.state.stateType))) }))+" ")])],1)],1)]}}])}),_c('add-file-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:('file'),expression:"'file'"}]}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('workflow.details.attachment.fileSubtitle', { stateName: _vm.$t(("workflow." + (_vm.state.stateType))) }))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }