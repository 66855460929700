import { render, staticRenderFns } from "./WorkflowHistory.vue?vue&type=template&id=7a13df71&scoped=true&"
import script from "./WorkflowHistory.vue?vue&type=script&lang=ts&"
export * from "./WorkflowHistory.vue?vue&type=script&lang=ts&"
import style0 from "./WorkflowHistory.vue?vue&type=style&index=0&id=7a13df71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a13df71",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VSlideXReverseTransition,VTimeline,VTimelineItem})
