





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AcknowledgementDialog extends Vue {
  acknowledgementDialog = false;
  acknowledgementResponse = false;
  acknowledgementLastClicked: { event: string; state: string } | null = null;

  @Prop()
  ackKey!: string;

  acknowledgement() {
    this.acknowledgementDialog = false;
    this.acknowledgementResponse = true;
    this.$emit('click', this.acknowledgementLastClicked!);
  }
}
