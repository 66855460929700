




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { urlRegex } from '@/helpers/integrationHelper';

@Component({ components: {} })
export default class AddLinkDialog extends Vue {
  show = false;
  urlRegex = urlRegex;
  @Prop()
  name!: string;
  @Prop()
  value!: string;

  @Prop()
  fieldName!: string;

  save() {
    this.show = false;
    this.$emit('change', { name: this.name, value: this.value, fieldName: this.fieldName });
  }
}
