


















import Vue from 'vue';
import WorkflowHistoryItem from '@/components/integrations/workflow/WorkflowHistoryItem.vue';
import { Component, Prop } from 'vue-property-decorator';
import { getColorFromState } from '@/helpers/integrationHelper';
import { WorkflowState } from '@/generated/graphql';

@Component({
  components: { WorkflowHistoryItem },
  methods: { getColorFromState },
})
export default class WorkflowHistory extends Vue {
  @Prop()
  workflowStates!: WorkflowState[];

  showRecertBar(i: number) {
    const previousState = this.workflowStates[i + 1];
    return previousState?.stateType === 'WorkflowStates::Production';
  }
}
