import { render, staticRenderFns } from "./WorkflowDetailsSidebar.vue?vue&type=template&id=fc68e8cc&scoped=true&"
import script from "./WorkflowDetailsSidebar.vue?vue&type=script&lang=ts&"
export * from "./WorkflowDetailsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./WorkflowDetailsSidebar.vue?vue&type=style&index=0&id=fc68e8cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc68e8cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardActions,VCardText,VSpacer,VTab,VTabItem,VTabs,VTabsItems})
