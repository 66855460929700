







import { Component, Prop } from 'vue-property-decorator';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { WorkflowState } from '@/generated/graphql';
import { mixins } from 'vue-class-component';
import { Date } from '@/mixins/date';

@Component({
  name: 'integration-history-item',
  components: {},
  methods: { getCssClassFromState },
})
export default class IntegrationHistoryItem extends mixins(Date) {
  @Prop()
  state!: WorkflowState;

  click() {
    document!.querySelector(`#state-${this.state.id}`)!.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
