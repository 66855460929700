









import { StartRecertificationMutationVariables, Workflow, WorkflowState, Integration } from '@/generated/graphql';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import AcknowledgementDialog from '@/components/integrations/workflow/AcknowledgementDialog.vue';
import { ROUTER } from '@/constants';

@Component({
  components: { AcknowledgementDialog },
})
export default class WorkflowRecertification extends Vue {
  $refs!: {
    acknowledgement: HTMLFormElement;
  };

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  integration!: Integration | null;

  @Action(`core/${CoreActions.FETCH_SELECTED_INTEGRATION}`)
  updateIntegration!: ActionMethod;

  @Getter(`workflow/${WorkflowGetter.IS_LOADING}`)
  loading!: boolean;

  @Prop()
  workflow!: Workflow;

  @Prop()
  currentState!: WorkflowState;

  @Prop()
  ackKey!: string;

  @Prop({ default: 'workflow.transitionDialog.activator' })
  btnText!: string;

  open() {
    if (this.integration?.certificationGroup) {
      const id = this.integration?.certificationGroup?.id || -1;
      this.$router.push({ name: ROUTER.GROUP_DETAILS, params: { groupId: id.toString() } });
    } else {
      this.$refs.acknowledgement.acknowledgementLastClicked = { event: '', state: 'planned' };
      if (!this.$refs.acknowledgement.acknowledgementResponse) {
        this.$refs.acknowledgement.acknowledgementDialog = true;
        return;
      }
    }
  }

  @Action(`workflow/${WorkflowActions.MUTATION_START_RECERTIFICATION}`)
  performCreateRecertState!: ActionMethod;

  async performUpdate(payload: StartRecertificationMutationVariables) {
    await this.performCreateRecertState(payload);
    this.updateIntegration({ integrationId: this.workflow.integrationId });
  }

  moveToRecertify(t: { event: string; state: string }) {
    this.$refs.acknowledgement.acknowledgementLastClicked = t;
    if (!this.$refs.acknowledgement.acknowledgementResponse) {
      this.$refs.acknowledgement.acknowledgementDialog = true;
      return;
    }
    if (this.currentState.comments!.length > 0) {
      this.currentState.comments![0].isEditable = false;
    }
    const payload: StartRecertificationMutationVariables = {
      integrationId: this.workflow.integrationId!.toString(),
      product: this.workflow.product!,
      assignee: 'system@fox.nlsn-eng-ops.com',
      data: {},
    };
    this.performUpdate(payload);

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('recertify', {
      event_category: 'workflow',
      event_label: 'recertify',
      value: this.workflow.integrationId!,
    });
  }
}
