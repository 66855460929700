












































import {
  Application,
  FetchDogApplicationQueryVariables,
  FetchIntegrationQueryVariables,
  FetchWorkflowsByProductQueryVariables,
  Integration,
  Workflow,
  WorkflowState,
} from '@/generated/graphql';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import WorkflowDetailSidebar from '@/components/integrations/workflow/WorkflowDetailsSidebar.vue';
import WorkflowHistory from '@/components/integrations/workflow/WorkflowHistory.vue';
import WorkflowRecertification from '@/components/integrations/workflow/WorkflowRecertification.vue';
import IntegrationCard from '@/components/integrations/workflow/WorkflowStateCard.vue';
import WorkflowHistoryDialog from '@/components/integrations/workflow/WorkflowHistoryDialog.vue';
import { getActiveProducts, getNameFromApplication } from '@/helpers/dogHelper';
import { ActionMethod, MutationMethod } from 'vuex';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import { WorkflowActions } from '@/store/workflow/actions.workflow';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { WorkflowMutations } from '@/store/workflow/mutations.workflow';
import { productDeprecated } from '@/helpers/integrationHelpers';
// @ts-ignore
import VueStickyDirective from '@renatodeleao/vue-sticky-directive/dist/vue-sticky-directive.esm.js';

@Component({
  components: {
    WorkflowDetailSidebar,
    WorkflowHistory,
    IntegrationCard,
    WorkflowRecertification,
    WorkflowHistoryDialog,
  },
  methods: { getNameFromApplication, getActiveProducts },
  directives: { sticky: VueStickyDirective },
})
export default class WorkflowDetail extends Vue {
  integrationStates = ['WorkflowStates::DevSanity', 'WorkflowStates::QualityAssurance', 'WorkflowStates::CertReview'];
  id: number | null = null;
  loadingApps = false;
  searchTerm = '';
  activeProduct = '';
  stickyOptions = { topSpacing: 128 };

  get title(): string {
    if (this.integration) {
      return `${this.integration.name!}`;
    }
    return '';
  }

  get selectedProperties() {
    return [];
  }

  get recertConfirmKey() {
    return 'workflow.transitionDialog.recertConfirmText';
  }

  get showRecertButton() {
    const product = this.workflows && this.workflows.length > 0 ? this.workflows[0]?.product : null;
    return this.recertifiability == 'recertifiable' && !productDeprecated(this.integration, product);
  }

  @Getter(`core/${CoreGetter.GET_APPLICATION}`)
  application!: Application;

  @Getter(`workflow/${WorkflowGetter.GET_SELECTED_WORKFLOWS}`)
  workflows!: [Workflow] | null;

  @Getter(`workflow/${WorkflowGetter.GET_WORKFLOW_STATES}`)
  workflowStates!: [WorkflowState];

  @Getter(`workflow/${WorkflowGetter.IS_RECERTIFIABLE}`)
  isRecertifiable!: boolean | null;

  @Getter(`core/${CoreGetter.GET_RECERTIFIABILITY}`)
  recertifiability!: string | null;

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  integration!: Integration;

  @Action(`core/${CoreActions.FETCH_APPLICATION}`)
  performApplicationCall!: ActionMethod;

  @Action(`workflow/${WorkflowActions.FETCH_WORKFLOWS_BY_PRODUCT}`)
  performWorkflowsCall!: ActionMethod;

  @Action(`core/${CoreActions.FETCH_SELECTED_INTEGRATION}`)
  performIntegrationCall!: ActionMethod;

  @Action(`workflow/${WorkflowActions.SUBSCRIBE_TO_WORKFLOW}`)
  performSubscribe!: ActionMethod;

  @Action(`workflow/${WorkflowActions.UNSUBSCRIBE_FROM_WORKFLOW}`)
  performUnsubscribe!: ActionMethod;

  @Mutation(`workflow/${WorkflowMutations.SET_SELECTED_WORKFLOWS}`)
  clearWorkflows!: MutationMethod;

  @Watch('$route')
  onRouteChange() {
    this.setup();
  }

  mounted() {
    this.setup();
    //@ts-ignore
    this.$gtag.pageview('/integration-details');
  }

  destroyed() {
    this.clearWorkflows(null);
  }

  beforeDestroy() {
    this.performUnsubscribe();
  }

  async setup() {
    const product = this.$route.params.product;
    this.activeProduct = product;
    const integrationId = parseInt(this.$route.params.integrationId);

    const integrationPayload: FetchIntegrationQueryVariables = { integrationId };
    const workflowPayload: FetchWorkflowsByProductQueryVariables = { integrationId, product };
    // TODO: add loading feedback
    await Promise.all([this.performIntegrationCall(integrationPayload), this.performWorkflowsCall(workflowPayload)]);
    const applicationPayload: FetchDogApplicationQueryVariables = { id: this.integration.appId! };
    this.performApplicationCall(applicationPayload);
    await this.performSubscribe();
  }

  showRecertBar(s: WorkflowState, workflowIndex: number) {
    return s.stateType === 'WorkflowStates::Production' && workflowIndex !== 0;
  }
}
